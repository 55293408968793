<template>
  <LoadingSpinner v-if="loading"></LoadingSpinner>
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view" v-else>
    <Form
      class="form"
      :validation-schema="validationSchema"
      @submit="submitData"
    >
      <div class="card-header cursor-pointer">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">
            {{ isCreatingNewClient ? "Create new" : "Edit" }} client
          </h3>
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button class="btn btn-success align-self-center">
            Save
          </button>

          <router-link to="/clients" class="btn btn-light align-self-center ">
            Back
          </router-link>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-5 col-xl-4 mt-10">
            <div class="row align-items-center form">
              <label class="col-3 fw-bold text-muted d-flex required"
                >Name</label
              >
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control col-12 col-md-9"
                  placeholder="Enter name"
                  name="name"
                  v-model="data.name"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-5">
              <label class="col-3 fw-bold text-muted d-flex d-md-flex required"
                >Country</label
              >
              <div class="col-12 col-md-9">
                <ValidatedCountriesDropdown
                  :name="'country_id'"
                  :initialValue="data.country_id"
                  @change="
                    id => {
                      data.country_id = id;
                    }
                  "
                ></ValidatedCountriesDropdown>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="country_id" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-5">
              <label class="col-3 fw-bold text-muted d-flex required"
                >City</label
              >
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control col-12 col-md-9"
                  placeholder="Enter city"
                  name="city"
                  v-model="data.city"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="city" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-5">
              <label class="col-3 fw-bold text-muted d-flex required"
                >TAX ID</label
              >
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control col-12 col-md-9"
                  placeholder="Enter Tax ID"
                  name="tax_id"
                  v-model="data.tax_id"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="tax_id" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-6 col-xl-7 offset-xl-1 mt-7 mt-lg-0  mb-5 mb-lg-0"
          >
            <div class="card">
              <div class="card-header">
                <div class="card-title m-0">
                  <h3 class="fw-bolder m-0">
                    {{
                      isCreatingNewClient
                        ? "Select key account manager"
                        : "Key account managers"
                    }}
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row" v-if="isCreatingNewClient">
                  <UsersDropdown
                    @change="
                      value => {
                        key_account_managers.push(value);
                      }
                    "
                    class="mb-4 col-12"
                  ></UsersDropdown>
                  <div class="col-12">
                    <Field
                      type="text"
                      class="form-control"
                      placeholder="Enter KAM revenue"
                      name="kam_revenue"
                      v-model="kam_revenue"
                    />

                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="kam_revenue" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    getFetchedSingle.key_account_managers &&
                      getFetchedSingle.key_account_managers.length == 0
                  "
                  class="row me-7 mb-4 "
                >
                  <span class="text-muted fs-6 col-8 offset-4"
                    >No key account manager specified</span
                  >
                </div>
                <div
                  v-else
                  class="row me-7 mb-4"
                  v-for="entry in getFetchedSingle.key_account_managers"
                  :key="entry.id"
                >
                  <div class="col-3 d-none d-lg-none d-xl-flex">
                    <Img
                      class="img-fluid height-100"
                      :src="entry.photo_url"
                    ></Img>
                  </div>

                  <div class="col-8 col-xl-6">
                    <div
                      class="d-flex justify-content-between align-items-start flex-wrap mb-2"
                    >
                      <div class="d-flex ">
                        <div class="d-flex align-items-center mb-2">
                          <router-link
                            :to="'/users/' + entry.id + '/personal'"
                            class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                          >
                            {{ entry.name }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap flex-stack ">
                      <div class="d-flex flex-column flex-grow-1 pe-8">
                        <div class="d-flex flex-wrap">
                          <div
                            class="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3"
                          >
                            <!--begin::Number-->
                            <div class="d-flex align-items-center">
                              <span class="svg-icon-3 svg-icon-dark me-2">
                                <inline-svg
                                  src="media/icons/duotone/Communication/Mail.svg"
                                />
                              </span>
                              <a
                                :href="'mailto:' + entry.email"
                                class="fs-5 text-dark text-hover-primary"
                              >
                                {{ entry.email }}
                              </a>
                            </div>
                          </div>
                          <div
                            class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-6 mb-3"
                            v-if="entry.phone"
                          >
                            <div class="d-flex align-items-center">
                              <span
                                class="svg-icon svg-icon-3 svg-icon-dark me-2"
                              >
                                <inline-svg
                                  src="media/icons/duotone/Interface/Phone.svg"
                                />
                              </span>
                              <a
                                :href="'tel:' + entry.phone"
                                class="fs-5  text-dark text-hover-primary"
                              >
                                {{ entry.phone }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-5 col-xl-4 mt-10">
            <div class="row align-items-center">
              <label class="col-3 fw-bold text-muted d-flex">Zip</label>
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control"
                  placeholder="Enter zip code"
                  name="zip"
                  v-model="data.zip"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="zip" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-5">
              <label class="col-3 fw-bold text-muted d-flex">Province</label>
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control"
                  placeholder="Enter Province"
                  name="province"
                  v-model="data.province"
                />
              </div>
            </div>
            <div class="row align-items-center mt-5">
              <label class="col-3 fw-bold text-muted d-flex">Phone</label>
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control"
                  placeholder="Enter phone"
                  name="phone"
                  v-model="data.phone"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="phone" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-5">
              <label class="col-3 fw-bold text-muted d-flex">Address</label>
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control"
                  placeholder="Enter address"
                  name="adress"
                  v-model="data.address"
                />
              </div>
            </div>
            <div class="row align-items-center mt-5">
              <label class="col-3 fw-bold text-muted d-flex"
                >Biling adress</label
              >
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control"
                  placeholder="Enter billing address"
                  name="billing"
                  v-model="data.billing_address"
                />
              </div>
            </div>
            <div class="row align-items-center mt-5">
              <label class="col-3 fw-bold text-muted d-flex"
                >Invoice email</label
              >
              <div class="col-12 col-md-9">
                <Field
                  type="text"
                  class="form-control"
                  placeholder="Enter invoice email"
                  name="invoice_email"
                  v-model="data.invoice_email"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="invoice_email" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-6 col-xl-7 offset-xl-1 mt-7 mt-lg-0 mb-5 mb-lg-0"
          >
            <div class="card">
              <div class="card-header">
                <div class="card-title m-0">
                  <h3 class="fw-bolder m-0">Miscellaneous</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="row me-7 mb-4">
                  <div class="col-12 col-xl-12">
                    <div class="row align-items-center mt-5">
                      <label class="col-3 fw-bold text-muted d-flex"
                        >Invited by</label
                      >
                      <div class="col-12 col-md-9">
                        <UsersDropdown
                          v-model="data.invited_by_id"
                        ></UsersDropdown>
                      </div>
                    </div>
                    <div class="row align-items-center mt-5">
                      <label class="col-3 fw-bold text-muted d-flex"
                        >Default country</label
                      >
                      <div class="col-12 col-md-9">
                        <CountriesDropdown
                          v-model="data.default_country_id"
                        ></CountriesDropdown>
                      </div>
                    </div>
                    <div class="row align-items-center mt-5">
                      <label class="col-3 fw-bold text-muted d-flex"
                        >Default city</label
                      >
                      <div class="col-12 col-md-9">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="Enter default city"
                          name="default_city"
                          v-model="data.default_city"
                        />
                      </div>
                    </div>
                    <div class="row align-items-center mt-5">
                      <label class="col-3 fw-bold text-muted d-flex"
                        >Default branch</label
                      >
                      <div class="col-12 col-md-9">
                        <BranchesDropdown
                          v-model="data.default_branch_id"
                        ></BranchesDropdown>
                      </div>
                    </div>
                    <div class="row align-items-center mt-5">
                      <label class="col-3 fw-bold text-muted d-flex"
                        >Details</label
                      >
                      <div class="col-12 col-md-9">
                        <textarea
                          class="form-control"
                          id="detailsTextArea"
                          rows="3"
                          placeholder="Enter client details"
                          v-model="data.details"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import ValidatedCountriesDropdown from "@/components/dropdown/VeeCountriesDropdown.vue";
import CountriesDropdown from "@/components/dropdown/CountriesDropdown.vue";
import BranchesDropdown from "@/components/dropdown/BranchesDropdown.vue";
import UsersDropdown from "@/components/dropdown/UsersDropdown.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Img from "@/components/general/Img.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { mapActions, mapGetters } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";

export default defineComponent({
  name: "EditClient",
  components: {
    Img,
    ValidatedCountriesDropdown,
    CountriesDropdown,
    BranchesDropdown,
    UsersDropdown,
    LoadingSpinner,
    ErrorMessage,
    Field,
    Form
  },
  data() {
    return {
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .required()
          .label("Name"),
        city: Yup.string()
          .required()
          .label("City"),
        tax_id: Yup.string()
          .required()
          .label("Tax ID"),
        country_id: Yup.number()
          .typeError("Country is a required field")
          .required()
          .label("Country"),
        kam_revenue: Yup.string()
          .matches(/^(\s*|\d+)$/, "KAM revenue must be a number")
          .nullable()
          .label("kam_revenue"),
        invoice_email: Yup.string()
          .matches(/^[^@\s]+@[^@\s]+\.[^@\s]+$/, "Invalid email address")
          .nullable()
          .label("invoice_email")
      }),
      data: {
        name: "",
        tax_id: "",
        city: "",
        country_id: "",
        zip: "",
        province: "",
        phone: "",
        invoice_email: "",
        address: "",
        billing_address: "",
        details: "",
        default_country_id: "",
        invited_by_id: "",
        default_city: "",
        default_branch_id: ""
      },
      key_account_managers: [],
      kam_revenue: "",
      isCreatingNewClient: false
    };
  },
  methods: {
    ...mapActions("ClientsModule", ["fetchSingle", "update", "createNew"]),
    submitData() {
      this.validationSchema.isValid(this.data).then(validationResult => {
        if (validationResult) {
          if (!this.isCreatingNewClient) {
            this.update({
              id: this.$route.params.id,
              data: this.data
            });
          } else {
            const payload = {
              ...this.data,
              key_account_managers: this.key_account_managers,
              kam_revenue: this.kam_revenue
            };
            this.createNew(payload);
          }
          Swal.fire({
            text: this.isCreatingNewClient
              ? "Created new client !"
              : "Client has been updated !",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          }).then(() => {
            this.$router.push(`/clients/`);
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters("ClientsModule", ["loading", "getFetchedSingle"])
  },
  mounted() {
    if (this.$route.params.id) {
      setCurrentPageTitle("Editing client profile");
      this.fetchSingle(this.$route.params.id);
    } else {
      setCurrentPageTitle("Creating client profile");
      this.isCreatingNewClient = true;
    }
  },
  watch: {
    loading() {
      if (this.loading == false) {
        this.data = this.getFetchedSingle;
      }
    }
  }
});
</script>

<style scoped>
.height-100 {
  height: 100%;
}
</style>
