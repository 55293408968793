<template>
  <div class="row gy-5 g-xl-8">
    <EditClient widget-classes="card-xxl-stretch mb-5 mb-xl-8"></EditClient>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import EditClient from "@/components/clients/CreateOrEditClient.vue";

export default defineComponent({
  name: "EditClientView",
  components: {
    EditClient
  },
});
</script>
