<template>
  <el-select
    v-model="value"
    clearable
    filterable
    size="large"
    placeholder="Select user"
    class="w-100"
  >
    <el-option
      v-for="item in users"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "UsersDropdown",
  data() {
    return {
      value: ""
    };
  },
  methods: {
    ...mapActions("ListsModule", ["fetchUsers"])
  },
  mounted() {
    this.fetchUsers();
  },
  computed: {
    ...mapGetters("ListsModule", ["users"])
  }
});
</script>
